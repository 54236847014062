import { template as template_80a37c6588c043ddb63768de4fe69c3b } from "@ember/template-compiler";
const FKLabel = template_80a37c6588c043ddb63768de4fe69c3b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
