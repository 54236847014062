import { template as template_04b04da676154052987303dc667a2955 } from "@ember/template-compiler";
const FKControlMenuContainer = template_04b04da676154052987303dc667a2955(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
